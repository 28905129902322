<template>
  <div>
    <Loading :loading="loading" />

    <h4 class="title is-4">Request Forms</h4>

    <div class="notification is-warning" v-if="warnInfo">
      <button class="delete" @click="warnInfo = false"></button>
      <b>Hey!</b> We recommend adding your
      <router-link :to="{ name: 'Settings' }">name, business name, and logo</router-link>
      before requesting forms. This information will appear on your requests and provide
      assurance that the request came from a trusted person.
    </div>

    <div class="box">
      <h6 class="title is-6">Create Permanent Links</h6>
      <div class="content" v-if="!formRequestLinks.length">
        <p>
          Request forms with a link. Links are intended to be used publicly, and may be
          resused as many times as you like. Your logo and information will appear at the
          top of the form to identify you as the recipient. If you provide a thank you
          URL, Tax ID Pro will redirect there upon completion. Otherwise, a default thank
          you page will be displayed.
        </p>
        <p>
          <b>Note:</b> If you'd like to request forms using our API, visit the
          <router-link :to="{ name: 'Developer' }">developer tools</router-link>.
        </p>
      </div>
      <div class="columns is-multiline is-variable is-2">
        <div class="column is-3">
          <Select
            :options="[
              { value: 'w9', name: 'W-9' },
              { value: 'w8ben', name: 'W-8BEN' },
            ]"
            :label="'Form No.'"
            :placeholder="'Select a Form'"
            :required="'Please select a form number.'"
            v-bind.sync="newLink.formNumber"
            :disabled="user.subType == 'expired'"
          />
        </div>
        <div class="column is-9">
          <div class="control is-expanded">
            <Field
              :label="'Thank You URL (optional)'"
              :placeholder="'https://yourwebsite.com/thanks'"
              :disabled="user.subType == 'expired'"
              v-bind.sync="newLink.thankYouUrl"
              :func="'url'"
              :required="false"
              :tooltip="`
                The thank you URL is optional. If you provide a URL, Tax ID Pro will redirect
                to that URL upon successful completion of the form. If you leave this blank,
                Tax ID Pro will show its own confirmation page.
              `"
            />
          </div>
        </div>
        <div class="column is-12">
          <div class="buttons" v-if="user.subType != 'expired'">
            <button
              class="button is-link"
              @click="createLink()"
              :class="{ 'is-loading': processingLink }"
              :disabled="!linkRequestValid"
            >
              Create Link
            </button>
          </div>
          <p class="has-text-danger" v-else>
            An active subscription is required for links.
            <router-link :to="{ name: 'Upgrade' }">Upgrade to Pro!</router-link>
          </p>
        </div>
      </div>
      <br v-if="formRequestLinks.length" />
      <h6 class="title is-6" v-if="formRequestLinks.length">Your Links</h6>
      <table class="table is-fullwidth" v-if="formRequestLinks.length">
        <thead>
          <tr>
            <th>Form No.</th>
            <th>URLs</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- TODO handle multiple forms submitted on the same request -->
          <tr v-for="(r, i) in formRequestLinks" :key="i">
            <td>{{ r.formNumberPretty }}</td>
            <td>
              <a :href="`${origin}/form/${r.formNumber}?token=${r.token}`">
                {{ `${origin}/form/${r.formNumber}?token=${r.token}` }}</a
              ><br />
              <span class="is-size-7 has-text-grey-light" v-if="r.thankYouUrl"
                >Thank you URL: {{ r.thankYouUrl }}</span
              >
              <span class="is-size-7 has-text-grey-light" v-else
                >Default thank you URL</span
              >
            </td>
            <td class="has-text-right">
              <a class="has-text-grey-light" @click="warnDeleteLink(r)"
                ><i class="fas fa-trash-alt"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="box">
      <h6 class="title is-6">Email Requests</h6>
      <div class="content" v-if="!formRequests.length">
        <p>
          Request forms via email. Email requests are intended to be one-time use. Your
          logo and information will appear at the top of the form to identify you as the
          recipient. Your request will be sent immediately, and you may track the progress
          of your request below.
        </p>
      </div>

      <div class="columns is-multiline is-variable is-2">
        <div class="column is-3">
          <Select
            :options="[
              { value: 'w9', name: 'W-9' },
              { value: 'w8ben', name: 'W-8BEN' },
            ]"
            :label="'Form No.'"
            :placeholder="'Select a Form'"
            :required="'Please select a form number.'"
            v-bind.sync="formNumber"
            :disabled="user.subType == 'expired'"
          />
        </div>
        <div class="column is-9">
          <Field
            :label="'Email'"
            :disabled="user.subType == 'expired'"
            :placeholder="'Email'"
            v-bind.sync="receiverEmail"
            :func="'email'"
            :tooltip="'Enter the email address of the individual or entity to receive the request.'"
          />
        </div>
        <div class="column is-12">
          <div class="buttons" v-if="user.subType != 'expired'">
            <button
              class="button is-link"
              @click="sendRequest()"
              :class="{ 'is-loading': processing }"
              :disabled="!(formNumber.valid && receiverEmail.valid)"
            >
              Send Request
            </button>
          </div>
          <p class="has-text-danger" v-else>
            An active subscription is required for form requests.
            <router-link :to="{ name: 'Upgrade' }">Upgrade to Pro!</router-link>
          </p>
        </div>
      </div>
      <div v-if="formRequests.length">
        <br />
        <h6 class="title is-6">Recently Emailed Requests</h6>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Form No.</th>
              <th>Email</th>
              <th>Sent</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <!-- TODO handle multiple forms submitted on the same request -->
            <tr v-for="(r, i) in formRequests" :key="i">
              <td>{{ r.formNumberPretty }}</td>
              <td>{{ r.receiverEmail }}</td>
              <td>{{ dayjs(r.createdAt).format('MM/DD/YYYY') }}</td>
              <td v-if="r.forms.length" style="text-transform: capitalize">
                <router-link :to="{ name: 'Form', params: { id: r.forms[0].id } }">
                  {{ r.status }}
                </router-link>
              </td>
              <td v-else style="text-transform: capitalize">
                {{ r.status }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="buttons" v-if="hasMore">
          <button
            class="button is-small"
            @click="showMore"
            :class="{ 'is-loading': loadingMore }"
          >
            Show More
          </button>
        </div>
      </div>
    </div>

    <!-- Delete Link Modal -->
    <div class="modal is-active" v-if="showConfirmDeleteLink">
      <div class="modal-background" @click="showConfirmDeleteLink = false"></div>
      <div class="modal-content">
        <div class="box">
          <h5 class="title is-5">Delete Link</h5>
          <div class="content">
            <p>Please confirm deletion of the following link:</p>
            <p>
              <a :href="`${origin}/form/w8ben?token=${linkToDelete.token}`">
                {{ `${origin}/form/w8ben?token=${linkToDelete.token}` }}</a
              >
            </p>
          </div>
          <div class="buttons">
            <button
              class="button is-danger"
              :class="{ 'is-loading': processingDeleteLink }"
              @click="deleteLink()"
            >
              Delete Link
            </button>
            <button
              class="button"
              @click="showConfirmDeleteLink = false"
              :disabled="processingDeleteLink"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>

    <FlashMessage :flash="flash" />
  </div>
</template>

<style scoped>
table.table thead th {
  border-width: 0 0 1px;
}
</style>

<script>
import dayjs from 'dayjs'
import { api, field, select, origin } from '@/modules/util'

export default {
  name: 'RequestForm',
  props: { user: Object },
  data() {
    return {
      origin,
      newLink: {
        formNumber: select(),
        thankYouUrl: field(),
      },
      newEmail: {
        formNumber: select(),
        receiverEmail: field(),
      },
      formNumber: select(),
      receiverEmail: field(),
      key: 0,
      flash: [],
      linkToDelete: null,
      processing: false,
      processingLink: false,
      formRequests: [],
      formRequestLinks: [],
      loading: true,
      loadingMore: true,
      hasMore: true,
      offset: 0,
      warnInfo: !this.user.name && !this.user.businessName,
      showConfirmDeleteLink: false,
      processingDeleteLink: false,
      // retryLink: 0,
    }
  },

  computed: {
    linkRequestValid() {
      return this.newLink.formNumber.valid && this.newLink.thankYouUrl.valid
    },
  },
  methods: {
    dayjs(s) {
      return dayjs(s)
    },
    warnDeleteLink(linkToDelete) {
      this.linkToDelete = linkToDelete
      this.showConfirmDeleteLink = true
    },
    async init() {
      // TODO add loading, general cleanup
      try {
        // this.loading = true
        // this.loadingMore = true
        // this.hasMore = true
        if (this.user.subType == 'expired') throw 'Inactive.'
        const res = await api.get('/formRequests/summary')
        // console.log(res.data.formRequests)
        if (res.data.formRequests.length < 10) this.hasMore = false
        this.formRequests = res.data.formRequests
        this.formRequestLinks = res.data.formRequestLinks
        this.loading = false
        this.loadingMore = false
      } catch (err) {
        this.loading = false
        this.loadingMore = false
        this.hasMore = false
      }
    },
    async showMore() {
      if (this.loadingMore || !this.hasMore) return
      try {
        this.loadingMore = true
        this.offset++
        const res = await api.get(`/formRequests/summary/more?offset=${this.offset}`)
        if (res.data.length < 10) this.hasMore = false
        if (res.data.length) this.formRequests.push(...res.data)
        this.loadingMore = false
      } catch (err) {
        this.flash.push({ msg: err, type: 'error' })
        this.loadingMore = false
      }
    },
    async sendRequest() {
      try {
        this.processing = true
        this.loadingMore = true
        await api.post('/formRequests/email', {
          formNumber: this.formNumber.value,
          receiverEmail: this.receiverEmail.value,
        })
        this.flash.push({ msg: 'Request sent!' })
        this.formNumber = select()
        this.receiverEmail = field()
        this.processing = false
        this.init()
      } catch (err) {
        this.processing = false
        this.flash.push({ msg: 'Request failed!', type: 'error' })
      }
    },
    async createLink() {
      try {
        this.processingLink = true
        await api.post('/formRequests/link', { formRequestLink: this.newLink })
        this.flash.push({ msg: 'Link created!' })
        this.newLink = { formNumber: select(), thankYouUrl: field({ valid: true }) }
        this.retryLink++
        this.processingLink = false
        this.init()
      } catch (err) {
        this.processingLink = false
        this.flash.push({ msg: 'Link failed!', type: 'error' })
      }
    },
    async deleteLink() {
      try {
        this.processingDeleteLink = true
        await api.delete(`/formRequests/${this.linkToDelete.id}`)
        this.flash.push({ msg: 'Deleted!' })
        this.processingDeleteLink = false
        this.showConfirmDeleteLink = false
        this.init()
      } catch (err) {
        this.processingDeleteLink = false
        this.flash.push({ msg: 'Delete failed!', type: 'error' })
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
